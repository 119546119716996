<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-shopping-cart mr-2"></i>
              <strong> Récoltes sur la production</strong>
              <strong-display v-if="submittedCreateHarvest">
                : {{productionsCultures.find(item => item.id==productionHarvest).name}}
              </strong-display>
              <div v-if="submittedCreateHarvest">
                <i class="fa fa-leaf mr-2 mt-1"></i>
                <strong>Cultures</strong>
                <strong-display>
                  : {{cultureHarvest.map(cul => cul.text).join(', ')}}
                </strong-display>
              </div>
            </div>
            <b-modal title="Nouvelle récolte" class="modal-primary" v-model="newHarvestModal"
                     @hide="onModalClose" no-close-on-backdrop="true" no-close-on-esc="true">
              <template v-if="fetchingExistingHarvest">
                <div class="text-center">
                  <b-spinner></b-spinner>
                  <br>
                  <!--todo customizer tous les messages de chargement-->
                  Recherche des productions...
                </div>
                <div slot="modal-footer"></div>
              </template>
              <template v-else>
                <template v-if="productionsCultures.length ==0">
                  <div class="text-center">
                    <description-message>
                      Vous ne pouvez démarrer aucune recolte dans votre exploitation. Pour lancer une récolte, vous
                      devez
                      avoir
                      terminé au moins une production.
                    </description-message>
                  </div>
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="warning" type="submit" @click="onBackPage">
                      Retourner à la page précédente
                    </button-spinner>
                  </div>
                </template>
                <template v-else>
                  <b-form>
                    <description-message>
                      Selectionnez une production pour pouvoir selectionner les cultures
                    </description-message>
                    <c-input container-class="mb-3 mt-3" type="select" label="Selectionnez une production"
                             v-model="productionHarvest"
                             :options="availableProductions" multiple>
                      <template slot="first">
                        <option :value="null" disabled>-- Sélectionnez une production --</option>
                      </template>
                      Veuillez sélectionner une production
                    </c-input>
                    <label class="text-muted font-weight-bold mb-0 mt-3">Selectionnez les cultures</label>
                    <multiselect v-model="cultureHarvest" :options="culturesForProduction"
                                 tag-placeholder="Add this as new tag" placeholder="-- Cliquez pour selectionner --"
                                 label="text"
                                 track-by="value" :multiple="true"
                                 :close-on-select="false" :searchable="false"
                                 :select-label="'ajoutez à la liste'" :deselect-label="'retirer de la liste'"
                    >
                      <div slot="noOptions" class="text-muted"
                           style="font-size: 13px;">Veuillez selectionner une production plus haut</div>
                    </multiselect>
                  </b-form>

                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onAddNewHarvest"
                                    :fetching="fetchingNewHarvest">
                      Enregistrer
                    </button-spinner>
                    <b-button variant="secondary" class="ml-2" @click="onBackPage">
                      Annuler
                    </b-button>
                  </div>
                </template>
              </template>
            </b-modal>
            <b-row>
              <b-modal :title="addModalTitle" class="modal-primary" v-model="addNodeModal" @hide="onAddModalClose">
                <b-form>
                  <p class="text-danger" v-if="hasError">{{errorMessage}}</p>

                  <b-input-group v-if="toAdd.length">
                    <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in toAdd">
                      {{item.name}}
                      <b-link @click.prevent="removeItem(item)">
                        <i class="fa fa-remove text-danger"></i>
                      </b-link>
                    </b-badge>
                  </b-input-group>
                  <p class="text-center" v-else>{{noItemText}}</p>

                  <b-input-group class="mb-3 mt-3">
                    <b-autocomplete-input :placeholder="searchPlaceholder" v-model="search"
                                          :data="searchAutocompleteData" class="autocomplete"
                                          @hit="submitSearch($event)" ref="searchAutocompleteInput"
                                          :serializer="item => item.name"
                                          :minMatchingChars=0>
                      <template slot="append">
                        <b-button variant="primary" @click.prevent="submitSearch()">
                          Ajouter
                        </b-button>
                      </template>
                    </b-autocomplete-input>
                  </b-input-group>
                </b-form>

                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAdd" :fetching=false>
                    Valider
                  </button-spinner>
                  <b-button variant="secondary" @click="addNodeModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
              <b-alert show dismissible>
                Effectuez un click droit sur le nom de la récolte ({{harvestData.text}}) pour pouvoir choisir les
                différentes cultures.
                Vous pouvez ajouter/supprimer les étapes et activités que vous désirez. Chaque fois que vous parametrez
                les informations d'une activité, veuillez l'enregistrer avant de continuer toute autre operation. Une
                fois terminée,
                vous pouvez enregistrer votre production toute entière.
              </b-alert>
              <b-col class="b-r-1">
                <tree-view
                  :data="treeData"
                  :contextMenuItems=ctxMenuItems
                  :showIcons=true
                  :renameNodeOnDblClick=false
                  @nodeSelect="onNodeSelect"
                  @contextMenuItemSelect="onCtxMenuSelect"
                  nodeLabelProp=text
                  prependIconClass=fa
                  defaultIconClass="fa-cog">
                </tree-view>
              </b-col>
              <b-col v-if="cultureSelected">
                <h5>Recolte de : {{selectedNodeData.text}}</h5>

                <c-input container-class="mb-3" type="quantity" label="Quantité minimale estimée"
                         placeholder="Ex: 12000"
                         v-model="cultureMinEstimation" unit="Kg" :state="cultureMinEstimationState">
                  Veuillez spécifier une quantité
                </c-input>
                <c-input container-class="mb-3" type="quantity" label="Quantité maximale estimée"
                         placeholder="Ex: 32000"
                         v-model="cultureMaxEstimation" unit="Kg" :state="cultureMaxEstimationState">
                  Veuillez spécifier une quantité
                </c-input>
                <div class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSaveHarvestCulture"
                                  :fetching=fetchingSave>
                    Enregistrer
                  </button-spinner>
                </div>
              </b-col>
              <b-col v-else-if="activitySelected">
                <h5>{{selectedNodeData.text}}</h5>
                <p>
                  Surface considérée :
                  <default-unit>{{surfaceParcel}} ha</default-unit>
                </p>
                <b-input-group class="mb-3 mt-3" v-if="selectedNodeData.status">
                  <b-form-text>Statut</b-form-text>
                  <b-badge class="font-sm ml-2" :variant="selectedNodeData.status.variant">
                    {{selectedNodeData.status.name}}
                  </b-badge>
                </b-input-group>

                <!--<text-recommended>La durée recommandée est de : {{activityRecommendationDuration}} jours
                </text-recommended>-->
                <c-input container-class="mb-3" type="quantity" label="Durée de l'activité" placeholder="Ex: 12"
                         v-model="activityDuration" unit="Jour(s)" :state="activityDurationState">
                  Veuillez spécifier une durée
                </c-input>

                <text-recommended>Le prix recommandé est :
                  {{activityRecommendationCost +' '+ exploitationCurrency +' /pers. / jour'}}
                </text-recommended>
                <c-input container-class="mb-3" type="quantity" label="Coût de l'activité" placeholder="Ex: 50000"
                         v-model="activityCost" :unit="exploitationCurrency + ' / pers. / jour'"
                         :state="activityCostState">
                  Veuillez spécifier un coût
                </c-input>

                <!--<text-recommended>Le nombre de personnes recommandé est de : {{activityRecommendationPersonNumber}}
                  personnes
                </text-recommended>-->
                <b-input-group v-if="activityStaff.length">
                  <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in activityStaff">
                    {{item.name}}
                    <b-link @click.prevent="removeStaff(item)">
                      <i class="fa fa-remove text-danger"></i>
                    </b-link>
                  </b-badge>
                </b-input-group>
                <p class="text-center text-muted" v-else>Aucun employé dans cette activité.</p>

                <b-input-group class="mb-3">
                  <b-autocomplete-input placeholder="Rechercher un employé..." v-model="staffSearch"
                                        :data="staffAutocompleteData" class="autocomplete"
                                        @hit="addStaff($event)" ref="staffAutocompleteInput"
                                        :serializer="item => item.name"
                                        :minMatchingChars=0>
                    <template slot="append">
                      <b-button variant="primary" @click.prevent="addStaff()">
                        Ajouter
                      </b-button>
                    </template>
                  </b-autocomplete-input>
                </b-input-group>
                <template v-if="activityInputs.length != 0">
                  <label class="text-muted font-weight-bold">Intrants</label>
                  <b-input-group class="mb-3" v-for="(input, i) in activityInputs">
                    <!--<text-recommended>
                      Recommandation : {{activityRecommendationInputs[i].quantity+' ' + input.unit+' '+input.inputName}}
                    </text-recommended>-->
                    <b-input-group-prepend class="w-75">
                      <b-input-group-text class="w-100">{{input.inputName}}</b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="number" class="form-control" placeholder="Qté"
                                  v-model="input.quantity" :state="activityInputQuantityState[i]"
                                  :aria-describedby="`activity-input-quantity-${i}-feedback`" min="0" step="1" />
                    <b-input-group-append class="unit">
                      <b-input-group-text class="w-100">{{input.unit}}</b-input-group-text>
                    </b-input-group-append>
                    <b-form-invalid-feedback :id="`activity-input-quantity-${i}-feedback`">
                      Veuillez entrer une quantité
                    </b-form-invalid-feedback>
                  </b-input-group>
                </template>

                <template v-if="activityEquipments.length !=0">
                  <label class="text-muted font-weight-bold">Equipements</label>
                  <b-input-group class="mb-3" v-for="(equipment, i) in activityEquipments">
                    <!--<text-recommended>
                      Recommandation : {{activityRecommendationEquipments[i].quantity+' '+equipment.equipmentName}}
                    </text-recommended>-->
                    <b-input-group-prepend class="w-75">
                      <b-input-group-text class="w-100">{{equipment.equipmentName}}</b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="number" class="form-control" placeholder="Qté"
                                  v-model="equipment.quantity" :state="activityEquipmentQuantityState[i]"
                                  :aria-describedby="`activity-equipment-quantity-${i}-feedback`" min="0" step="1" />
                    <b-input-group-append class="unit">
                      <b-input-group-text class="w-100">{{equipment.unit}}</b-input-group-text>
                    </b-input-group-append>
                    <b-form-invalid-feedback :id="`activity-equipment-quantity-${i}-feedback`">
                      Veuillez entrer une quantité
                    </b-form-invalid-feedback>
                  </b-input-group>
                </template>

                <div class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSaveActivity"
                                  :fetching=false>
                    Enregistrer
                  </button-spinner>
                </div>
              </b-col>
              <b-col v-else>
                <h5>Récoltes</h5>

                <c-input container-class="mb-3 mt-3" label="Nom de la récolte" placeholder="Ex: Cacao 2019"
                         v-model="harvestName" :state="harvestNameState">
                  Veuillez saisir un nom
                </c-input>

                <c-input container-class="mb-3" type="textarea" label="Description"
                         placeholder="Entrez une description..." v-model="harvestDescription">
                </c-input>

                <c-input container-class="mb-3" type="datetime" label="Date de lancement" v-model="harvestStartDay"
                         :time.sync="harvestStartTime" :state="harvestStartDayTimeState">
                  Veuillez entrer une durée
                </c-input>

                <div class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSaveHarvest"
                                  :fetching=fetchingSaveHarvest>
                    Enregistrer
                  </button-spinner>
                  <button-spinner variant="primary" type="submit" class="px-4 ml-3" @click="onSaveAndLaunchHarvest"
                                  :fetching=fetchingSaveAndLaunchHarvest>
                    Enregistrer et Lancez
                  </button-spinner>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
  import TreeView from "../../../components/tree-component/components/TreeView"
  import {Api, Toast, price, Regex} from '../../../helpers'
  import Multiselect from 'vue-multiselect'

  class TreeNode {
    children = []
    parent = null

    constructor(props) {
      this.fill(props)
    }

    fill(props) {
      for (let key in props) {
        if (props.hasOwnProperty(key))
          this[key] = props[key]
      }
    }

    toJSON() {
      const obj = {}
      for (let key in this) {
        if (this.hasOwnProperty(key) && key !== "children")
          obj[key] = this[key]
      }
      // if (this.children && this.children.length)
      //   obj.children = this.children.map(child => child.toJSON())

      return obj
    }
  }

  export default {
    name: "ExploitationCreateHarvest",
    components: {Multiselect, TreeView},
    data() {
      return {
        newHarvestModal: true,
        fetchingExistingHarvest: false,
        fetchingNewHarvest: false,
        fetchingSaveHarvest: false,
        fetchingSaveAndLaunchHarvest: false,
        productionHarvest: null,
        cultureHarvest: [],
        testMulti: null,
        options: [
          {
            name: 'adrien',
            code: '0'
          },
          {
            name: 'stephane',
            code: '1'
          },
          {
            name: 'hereads',
            code: '2'
          },
          {
            name: 'adasdfsdrien',
            code: '3'
          },
        ],
        productionsCultures: [],
        culturesForProduction: [],

        error: null,
        submittedCreateHarvest: false,
        submittedHarvest: false,
        submittedActivity: false,
        submittedCulture: false,

        harvestStatus: null,
        harvestName: '',
        harvestDescription: '',
        harvestStartDay: '',
        harvestStartTime: '',

        addNodeModal: false,
        toAdd: [],
        search: '',

        nodeTypes: {
          Recolte: {
            icon: "fa-cog",
            ctxMenuItems: [],
            children: ["Culture"],
            modal: {
              noItemText: "Aucune culture sélectionnée.",
              searchPlaceholder: "Ajouter une culture...",
              autocompleteData: "cultures"
            }
          },
          Culture: {
            icon: "fa-leaf",
            ctxMenuItems: [
              {code: "ADD_CHILD", label: "Ajouter des activités"},
              {code: "DEL", label: "Supprimer la culture"}
            ],
            children: ["Activity"],
            modal: {
              noItemText: "Aucune activité sélectionnée.",
              searchPlaceholder: "Ajouter une activité...",
              autocompleteData: "activities"
            },
            refKey: "cultureId"
          },
          Activity: {
            icon: "fa-gavel",
            ctxMenuItems: [
              {code: "DEL", label: "Supprimer l'activité"}
            ],
            children: [],
            refKey: "activityId",
            properties: [
              {key: "duration", defaultValue: ''},
              {key: "cost", defaultValue: ''},
              {key: "staff", defaultValue: () => []},
              {
                key: "inputs",
                defaultValue: model => model.inputs.map(el => ({
                  inputId: el.id,
                  inputName: el.name,
                  quantity: 0,
                  unit: el.unit
                }))
              },
              {
                key: "equipments",
                defaultValue: model => model.equipments.map(el => ({
                  equipmentId: el.id,
                  equipmentName: el.name,
                  quantity: 0,
                  unit: el.unit
                }))
              }
            ]
          }
        },
        harvestData: new TreeNode({
          id: 0, text: "Récoltes", type: "Recolte",
        }),
        ctxMenuItems: [],
        selectedNode: null,

        cultureMinEstimation: '',
        cultureMaxEstimation: '',

        activities:[],
        activityDuration: '',
        activityCost: '',
        activityStaff: [],
        staffSearch: '',
        activityInputs: [],
        activityEquipments: [],
        staff: [],

        activityRecommendationDuration: null,
        activityRecommendationCost: null,
        activityRecommendationPersonNumber: null,
        activityRecommendationInputs: [],
        activityRecommendationEquipments: [],
        defaultsParameters: [],
        surfaceParcel: ''
      }
    },
    created() {
      this.fetchingExistingHarvest = true
      Api.get('/exploitation/production/harvest/available', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.productionsCultures = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingExistingHarvest = false
        })
      Api.get('/exploitation/activity/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.activities = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {

        })
      Api.get('/exploitation/staff/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.staff = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })

    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
      productionHarvest() {
        const culturesIntermediate = this.productionsCultures.find(item => item.id == this.productionHarvest).cultures
        this.culturesForProduction = culturesIntermediate.map(cul => ({
          value: cul.id,
          text: cul.name
        }))
      },
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      availableProductions() {
        return this.productionsCultures.map(prod => ({
          value: prod.id,
          text: prod.name
        }))
      },
      treeData() {
        const harvestData = this.harvestData
        if (this.harvestName)
          harvestData.text = this.harvestName
        else
          harvestData.text = 'Recolte ' + new Date().toDateString()

        return [harvestData]
      },
      cultureSelected() {
        return this.selectedNode && this.selectedNode.data.type === "Culture"
      },
      activitySelected() {
        return this.selectedNode && this.selectedNode.data.type === "Activity"
      },

      harvestNameState() {
        return !this.submittedHarvest || this.harvestName.trim().length >= 3 ? null : false
      },
      harvestStartDayTimeState() {
        return !this.submittedHarvest || this.harvestStartDay != "" ? null : false
      },
      cultureMinEstimationState() {
        return !this.submittedCulture || (this.cultureMinEstimation == '' || Regex.isPositiveNumber(this.cultureMinEstimation)) ? null : false
      },
      cultureMaxEstimationState() {
        return !this.submittedCulture || (this.cultureMaxEstimation == '' || Regex.isPositiveNumber(this.cultureMaxEstimation)) ? null : false
      },
      activityDurationState() {
        return !this.submittedActivity || Regex.isPositiveNumber(this.activityDuration) ? null : false
      },

      activityCostState() {
        return !this.submittedActivity || Regex.isPositiveNumber(this.activityCost) ? null : false
      },
      activityInputQuantityState() {
        return !this.submittedActivity ? [null] : this.activityInputs.map(input => {
          return Regex.isPositiveNumber(input.quantity) ? null : false
        })
      },
      activityEquipmentQuantityState() {
        return !this.submittedActivity ? [null] : this.activityEquipments.map(equip => {
          return Regex.isPositiveNumber(equip.quantity) ? null : false
        })
      },
      staffAutocompleteData() {
        return this.staff
      },
      selectedNodeData() {
        return this.selectedNode && this.selectedNode.data ? this.selectedNode.data : {}
      },
      exploitationCurrency() {
        return price()
      },
      harvestStartDayTime() {
        if (this.harvestStartTime)
          return this.harvestStartDay + ' ' + this.harvestStartTime
        return this.harvestStartDay
      },

      // Modal
      addModalTitle() {
        if (!this.selectedNode)
          return ''

        return this.nodeTypes[this.selectedNode.data.type].ctxMenuItems.length !=0
          ? this.nodeTypes[this.selectedNode.data.type].ctxMenuItems[0].label
          : ""
      },
      noItemText() {
        if (!this.selectedNode)
          return ''

        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? modal.noItemText : null
      },
      searchPlaceholder() {
        if (!this.selectedNode)
          return ''

        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? modal.searchPlaceholder : null
      },
      searchAutocompleteData() {
        if (!this.selectedNode)
          return []

        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? this[modal.autocompleteData] : []
      },
    },
    methods: {
      onModalClose() {
      },
      onAddNewHarvest() {
        this.fetchingNewHarvest = true
        Api.get('/exploitation/production/harvest/activities', {
          exploitationId: this.exploitationId,
          productionId: this.productionHarvest,
          cultures: this.cultureHarvest.map(cul => cul.value)
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.newHarvestModal = false
              this.submittedCreateHarvest = true
              const harvestActivities = res.data.data
              const currentNode = this.harvestData
              // this.surfaceParcel = harvestActivities.production.parcel.area
              this.harvestData.children = harvestActivities.map(culture => {
                const cultNode = new TreeNode()
                cultNode.fill({
                  ...culture,
                  id: "Culture-" + culture.id,
                  icon: this.nodeTypes.Culture.icon,
                  type: "Culture",
                  text: culture.name,
                  cultureId: culture.id,
                  activities: undefined,
                  parent: currentNode,
                  minEstimation: '',
                  maxEstimation: '',
                  ctxMenuItems: [
                    {code: "ADD_CHILD", label: "Ajouter des activités"},
                    {code: "DEL", label: "Supprimer la culture"}
                  ],
                  actions: [
                    {
                      icon: 'fa-plus',
                      text: 'Ajouter act'
                    }, {
                      icon: 'fa-minus',
                      text: 'Supprimer culture',
                    }
                  ],
                  children: culture.activities.map(activity => {
                    const activityNode = new TreeNode()
                    activityNode.fill({
                      ...activity,
                      id: "Activity-" + activity.id,
                      icon: this.nodeTypes.Activity.icon,
                      type: "Activity",
                      text: activity.name,
                      activityId: activity.id,
                      parent: cultNode,
                      children: [],
                      staff: [],
                      inputs: activity.inputs.map(input => ({
                        ...input,
                        inputId: input.id,
                        inputName: input.name
                      })),
                      equipments: activity.equipments.map(equipment => ({
                        ...equipment,
                        equipmentId: equipment.id,
                        equipmentName: equipment.name
                      })),
                      actions: [{
                        icon: 'fa-minus',
                        text: 'Supprimer act',
                      }],
                      ctxMenuItems: [
                        {code: "DEL", label: "Supprimer l'activité"}
                      ],
                      //to verifiy if it's an activity to display the real color in the TreeNode component
                      isActivity: true
                    })
                    return activityNode
                  })
                })
                return cultNode
              })
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
        Api.get('/exploitation/staff/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.staff = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })

      },

      onCtxMenuSelect({code}, node) {
        const args = code.split('-')
        switch (args[0]) {
          case "ADD_CHILD":
            this.addNodeModal = true
            break
          case "DEL":
            this.deleteNode(node.data.id)
            break
        }
        return true
      },
      onNodeSelect(node, selected) {
        if (selected) {
          this.selectedNode = node
          this.ctxMenuItems = this.nodeTypes[node.data.type].ctxMenuItems
          if (node.data.type == 'Activity') {
            this.submittedActivity = false
            this.activityDuration = node.data.duration
            this.activityCost = node.data.cost
            this.activityInputs = node.data.inputs
            this.activityEquipments = node.data.equipments
            this.activityStaff = node.data.staff


            // we begin with recommandations
            this.defaultsParameters = {
              duration: node.data.duration,
              cost: this.activityCost,
              personNumber: node.data.personNumber,
              inputs: node.data.inputs,
              equipments: this.activityEquipments
            }
            const valuePersonNumber = Math.ceil(this.surfaceParcel / 10)
            if (valuePersonNumber <= 1 || valuePersonNumber <= this.defaultsParameters.personNumber) {
              this.activityRecommendationPersonNumber = this.defaultsParameters.personNumber
              this.activityRecommendationDuration = this.multiplyWithSurface(this.defaultsParameters.duration * this.defaultsParameters.personNumber / this.activityRecommendationPersonNumber)
            } else {
              this.activityRecommendationPersonNumber = valuePersonNumber
              this.activityRecommendationDuration = this.multiplyWithSurface(this.defaultsParameters.duration * this.defaultsParameters.personNumber / this.activityRecommendationPersonNumber)
            }
            this.activityRecommendationCost = this.activityCost
            this.activityRecommendationInputs = this.activityInputs.map(item => ({
              ...item,
              quantity: this.multiplyWithSurface(item.quantity)
            }))
            this.activityRecommendationEquipments = this.activityEquipments.map(item => ({
              ...item
            }))
          }
          else if (node.data.type == 'Culture') {
            this.submittedCulture = false
            this.cultureMinEstimation = node.data.minEstimation || ''
            this.cultureMaxEstimation = node.data.maxEstimation || ''
          }
        }
      },
      deleteNode(id, root = this.harvestData) {
        if (root.children) {
          const childrenLength = root.children.length
          root.children = root.children.filter(el => el.id !== id)
          if (childrenLength === root.children.length)
            root.children.forEach(node => this.deleteNode(id, node))
        }
      },
      findNode(id, root = this.harvestData) {
        if (root.id === id)
          return root
        if (root.children) {
          let node
          for (let i = 0; i < root.children.length; i++) {
            node = this.findNode(id, root.children[i])
            if (node)
              return node
          }
        }
        return null
      },
      setHarvestData(data = {}) {
        this.harvestStatus = data.status
        this.harvestName = data.name
        this.harvestDescription = data.description
        if (data.startDate) {
          const dateParts = data.startDate.split(' ')
          if (dateParts.length)
            this.harvestStartDay = dateParts[0]
          if (dateParts.length >= 2)
            this.harvestStartTime = dateParts[1].toString()
        }
        this.harvestData = new TreeNode({
          id: data.id,
          text: data.name,
          type: "Production",
          children: data.cultures.map(culture => {
            const cultureNode = new TreeNode()
            cultureNode.fill({
              ...culture,
              id: "Culture-" + culture.id,
              icon: this.nodeTypes.Culture.icon,
              type: "Culture",
              text: culture.name,
              cultureId: culture.id,
              parent: this.harvestData,
              children: culture.cultureSteps.map(cultureStep => {
                const stepNode = new TreeNode()
                stepNode.fill({
                  ...cultureStep,
                  id: "CultureStep-" + cultureStep.id,
                  icon: this.nodeTypes.CultureStep.icon,
                  type: "CultureStep",
                  text: cultureStep.name,
                  cultureStepId: cultureStep.id,
                  status: cultureStep.status,
                  activities: undefined,
                  parent: cultureNode,
                  children: cultureStep.activities.map(activity => new TreeNode({
                    ...activity,
                    id: "Activity-" + activity.id,
                    icon: this.nodeTypes.Activity.icon,
                    type: "Activity",
                    text: activity.name,
                    activityId: activity.id,
                    parent: stepNode,
                    status: activity.status,
                    personNumber: activity.personNumber,
                    children: [],
                    staff: activity.staff,
                    inputs: activity.inputs.map(input => ({
                      ...input,
                      inputId: input.id,
                      inputName: input.name
                    })),
                    equipments: activity.equipments.map(equipment => ({
                      ...equipment,
                      equipmentId: equipment.id,
                      equipmentName: equipment.name
                    }))
                  }))
                })
                return stepNode
              })
            })
            return cultureNode
          })
        })


      },
      getHarvestData() {
        return this.harvestData.children.map(culture => ({
          cultureId: culture.cultureId,
          minEstimation: culture.minEstimation,
          maxEstimation: culture.maxEstimation,
          activities: culture.children
        }))
      },
      onSaveHarvestCulture(noToast) {
        this.submittedCulture = true
        if (!this.validCulture()) return
        this.selectedNode.data.minEstimation = this.cultureMinEstimation
        this.selectedNode.data.maxEstimation = this.cultureMaxEstimation
        if (!noToast)
          Toast.success("Estimations de la récolte du ", this.selectedNode.text, " enregistrée avec succès")
      },
      onSaveActivity(noToast) {
        this.submittedActivity = true
        if (!this.validActivity()) return
        this.selectedNode.data.duration = this.activityDuration
        this.selectedNode.data.cost = this.activityCost
        this.selectedNode.data.staff = this.activityStaff
        this.selectedNode.data.inputs = this.activityInputs
        this.selectedNode.data.equipments = this.activityEquipments
        if (!noToast)
          Toast.success("Modification enregistrées avec succès !")
      },
      onSubmit(route, fetchingAttribute, callback, data = {}) {
        this[fetchingAttribute] = true
        Api.post(route, {
          exploitationId: this.exploitationId,
          productionId: this.productionHarvest,
          startDate: this.harvestStartDayTime,
          name: this.harvestName,
          description: this.harvestDescription,
          cultures: this.getHarvestData(),
          ...data
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              callback(res.data.data)
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
            this.error = error
          })
          .then(() => {
            this[fetchingAttribute] = false
          })
      },
      onSaveHarvest() {
        this.submittedHarvest = true
        if (!this.validHarvest()) return
        this.onSubmit('/exploitation/production/harvest/create', "fetchingSaveHarvest", data => {
          Toast.success('Récolte enregistrée avec succès !')
          this.$router.push({
            name: 'ExploitationHarvests',
            params: {id: this.exploitationId}
          })
        })
      },
      onSaveAndLaunchHarvest() {
        this.submittedHarvest = true
        if (!this.validHarvest()) return
        this.onSubmit('/exploitation/production/harvest/create-start', "fetchingSaveAndLaunchHarvest", data => {
          Toast.success('Récolte enregistrée et lancée avec succès !')
          this.$router.push({
            name: 'ExploitationHarvest',
            params: {
              id: this.exploitationId,
              harvestId: data.id
            }
          })
        })
      },
      clearStaffAutocompleteInput() {
        this.$refs.staffAutocompleteInput.inputValue = ''
        this.staffSearch = ''
      },
      addStaff(item) {
        if (!item && this.staffSearch.trim() !== '')
          item = this.staffAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.staffSearch.toLowerCase())
          )

        if (item) {
          if (!this.activityStaff.includes(item))
            this.activityStaff.push(item)
        }
        else
          alert('Aucun élément trouvé !')

        this.clearStaffAutocompleteInput()
      },
      removeStaff(item) {
        this.activityStaff = this.activityStaff.filter(el => el.id !== item.id || el.user !== item.user)
      },
      onBackPage() {
        this.$router.go(-1)
      },
      multiplyWithSurface(element) {
        return Math.ceil(element * this.surfaceParcel)
      },
      validHarvest() {
        return this.harvestName.trim().length >= 3
          && this.harvestStartDay != ""
      },
      validCulture() {
        return (this.cultureMinEstimation == '' || Regex.isPositiveNumber(this.cultureMinEstimation))
          && (this.cultureMaxEstimation == '' || Regex.isPositiveNumber(this.cultureMaxEstimation))
      },
      validActivity() {
        if (!Regex.isPositiveNumber(this.activityDuration))
          return false
        if (!Regex.isPositiveNumber(this.activityCost))
          return false
        for (let i = 0 ; i< this.activityInputs.length;i++){
          if (!Regex.isPositiveNumber(this.activityInputs[i].quantity))
            return false
        }
        for (let i = 0 ; i< this.activityEquipments.length;i++){
          if (!Regex.isPositiveNumber(this.activityEquipments[i].quantity))
            return false
        }
        return true
      },

      // Modal
      clearSearchAutocompleteInput() {
        this.$refs.searchAutocompleteInput.inputValue = ''
        this.search = ''
      },
      onAddModalClose() {
        this.toAdd = []
        this.clearSearchAutocompleteInput()
      },
      removeItem(item) {
        this.toAdd = this.toAdd.filter(el => el.id !== item.id)
      },
      submitSearch(item) {
        if (!item && this.search.trim() !== '')
          item = this.searchAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.search.toLowerCase())
          )

        if (item) {
          if (!this.toAdd.includes(item))
            this.toAdd.push(item)
        }
        else
          alert('Aucun élément trouvé !')

        this.clearSearchAutocompleteInput()
      },
      onSubmitAdd() {
        if (!this.toAdd.length)
          return

        const parentType = this.nodeTypes[this.selectedNode.data.type]
        const type = this.nodeTypes[parentType.children[0]]
        if (this.selectedNode.data.type == "Culture"){
          this.toAdd.forEach(item => {
            const node = new TreeNode({
              id: parentType.children[0] + '-' + item.id,
              icon: type.icon,
              text: item.name,
              type: parentType.children[0],
              parent: this.selectedNode.data.ref,
              actions: [{
                icon: 'fa-minus',
                text: 'Supprimer act',
              }],
              ctxMenuItems: [
                {code: "DEL", label: "Supprimer l'activité"}
              ],
              //to verifiy if it's an activity to display the real color in the TreeNode component
              isActivity: true
            })
            if (type.refKey)
              node[type.refKey] = item.id

            if (type.properties)
              type.properties.forEach(
                prop => node[prop.key] = typeof prop.defaultValue === "function"
                  ? prop.defaultValue(item)
                  : prop.defaultValue
              )
            this.selectedNode.data.children.push(node)
          })
        }
        this.selectedNode.expand()
        this.addNodeModal = false
      },
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .tree-node{
    display: inherit !important;
  }
</style>
<style scoped>
  .autocomplete{
    width: 100%;
    border-radius: 0;
  }

  strong-display{
    color: grey;
    font-family: "Segoe UI Historic";
  }

  default-unit{
    font-size: 20px;
    font-family: Candara;
    font-weight: bold;
    text-decoration: underline;
  }

  text-recommended{
    font-style: italic;
    margin-top: 10px;
    font-weight: 600;
    color: #009000;
  }

  text-recommended::after,
  text-recommended::before{
    content: " \2022 ";
  }
</style>
